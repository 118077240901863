/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/es';
import Accordion from '@material-ui/core/Accordion';
import { Tooltip, withStyles } from '@material-ui/core/';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';
import Phone from '@material-ui/icons/Phone';
import AddIcon from '@material-ui/icons/Add';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as CatalogsApi from '../../api/catalogs';
import MenuTableOptions from '../MenuTableOptions';
import MenuReservationStatus from '../MenuReservationStatus';
import prepareCategoryList from '../../utils/prepareCategoryList';
import ReservationsContext from '../../layouts/Reservations/ReservationsContext/ReservationsContext';
import isTruthy from '../../utils/isTruthy';
import * as ReservationsApi from '../../api/restaurants/reservations';
import DialogCancelReservation from '../DialogCancelReservation';
import DialogEmail from '../DialogEmail';
import DialogEditClient from '../DialogEditClient';
import './ReservationsAccordion.scss';
import DefaulImage from '../../images/default.jpeg';
import DialogImage from '../DialogImage';
import DialogHistory from '../DialogHistory';
import Iconcake from '../../images/errorIcons/cake.png';
import Iconstar from '../../images/categoryIcons/estrella.png';
import Iconwaiting from '../../images/categoryIcons/waiting.png';
import Iconevent from '../../images/categoryIcons/evento.png';

const CustomColorIconButton = withStyles({
  root: {
    color: '#000000',
    paddingTop: '0px',
  },
  label: {
    color: '#000',
    border: 'solid 1px #000000',
    borderRadius: '20px',
    marginRight: '10px',
    paddingTop: '0px',
    height: '26px',
  },
})(IconButton);

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'black !important',
    color: 'rgba(255, 255, 255, 1)',
    border: 'solid 1px rgb(0,0,0)',
    boxShadow: 'none',
    fontSize: 11,
  },
}))(Tooltip);
const ReservationsAccordion = () => {
  const {
    apiCallConfig, filterdReservations, setApiCallConfig, title,
  } = useContext(ReservationsContext);
  const [pagination] = useState(apiCallConfig);
  const [loading, setLoading] = useState(true);
  const [catalogReservationStatus, setCatalogReservationStatus] = useState({});
  const [cancelDialog, setCancelDialog] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [EditClientDialog, setEditClientDialog] = useState(false);
  const [editClientDialogData, setEditClientDialogData] = useState({ name: '', clientId: null });
  const [cancelDialogData, setCancelDialogData] = useState({ name: '', reservationId: null });
  const [emailDialogData, setEmailDialogData] = useState({ name: '', email: '', phone: '' });
  const [order] = React.useState(apiCallConfig.sortDirection);
  const [orderBy] = React.useState(apiCallConfig.sortCategory);
  const [imageDialog, setImageDialog] = useState(false);
  const [imageDialogData, setImageDialogData] = useState('');
  const [historyDialog, setHistoryDialog] = useState(false);
  const [historyDialogData, setHistoryDialogData] = useState({
    created_by: '', status_modified_by: '', modified_by: '', deleted_by: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const openemailDialog = (name, email, phone) => {
    setEmailDialogData({ name, email, phone });
    setEmailDialog(true);
  };
  const snackbarHandleClose = () => {
    setSnackbar({ open: false });
  };
  const openCancelDialog = (reservationId, name) => {
    setCancelDialogData({ name, reservationId });
    setCancelDialog(true);
  };
  const openEditClientDialog = (name, clientId) => {
    setEditClientDialogData({ name, clientId });
    setEditClientDialog(true);
  };

  const menuOptions = (reservationId, name, table, date, clientId) => {
    //escape the '/' character from table url
    const tableNumber = table.replace(/\//g, '%2F');
    return [
      { key: 1, string: 'EDITAR RESERVA', action: () => navigate(`/editar-reservacion/${reservationId}/${tableNumber}/${date}`) },
      { key: 2, string: 'CANCELAR', action: () => { openCancelDialog(reservationId, name); } },
      { key: 3, string: 'EDITAR CLIENTE', action: () => { openEditClientDialog(name, clientId); } },
    ];
  };

  const closeEditClientDialog = () => {
    setEditClientDialog(false);
  };
  const closeCancelDialog = () => {
    setCancelDialog(false);
  };
  const closeEmailDialog = () => {
    setEmailDialog(false);
  };
  const closeImageDialog = () => {
    setImageDialog(false);
  };
  const closeHistoryDialog = () => {
    setHistoryDialog(false);
  };

  const renderDayRow = () => (
    <div className="w-100">
      <span className="limiterRow separator">
        DÍA
        {' '}
        <span className="guion">-</span>
        {' '}
        <span className="fontAndaleMono">
          {title}
        </span>
      </span>
    </div>

  );
  const renderNightRow = () => (
    <div className="w-100">
      <span className="limiterRow separator">
        NOCHE
        {' '}
        <span className="guion">-</span>
        {' '}
        <span className="fontAndaleMono">
          {title}
        </span>
      </span>
    </div>
  );
  const renderpardepan = () => (
    <>
      <div className="cakespan">
        <img src={Iconstar} style={{ width: '17px', height: '17px', marginRight: '4px' }} alt="cake icon" />
        <span style={{ paddingTop: '1px', marginRight: '4px' }}>Reserva Ya!</span>
      </div>
    </>
  );
  const renderBirthday = (value) => {
    const bday = moment(value);
    const iscurrentDate = bday.isSame(new Date(), 'day');
    const iscurrentMonth = bday.isSame(new Date(), 'month'); // false, different year
    return iscurrentDate && iscurrentMonth && (
      <>
        <div className="cakespan">
          <img src={Iconcake} alt="cake icon" className="iconcake" />
          <span className="cakeText">Hoy cumple años</span>
        </div>
      </>
    );
  };
  const renderwaitinglist = () => (
    <>
      <div className="cakespan">
        <img src={Iconwaiting} style={{ width: '17px', height: '17px', marginRight: '4px' }} alt="cake icon" />
        <span style={{ paddingTop: '1px', marginRight: '4px' }}>PENDIENTE POR CONTESTAR</span>
      </div>
    </>
  );
  const renderevent = (event) => (
    <>
      <LightTooltip title={`La zona ha sido reservada por un evento que se celebra el ${moment(event.dateRaw).format('LL hh:mm A')}. La zona ha sido bloqueada desde el ${moment(event.start_date).format('LL hh:mm A')}, hasta el  ${moment(event.end_date).format('LL hh:mm A')} `} TransitionComponent={Zoom} placement="top-center">
        <div className="cakespan">
          <img src={Iconevent} style={{ width: '17px', height: '17px', marginRight: '4px' }} alt="cake icon" />
          <span style={{ paddingTop: '1px', marginRight: '4px' }}>
            <b>Evento</b>
          </span>
        </div>
      </LightTooltip>
    </>
  );
  const cancelReservation = async () => {
    console.log(cancelDialogData);
    try {
      const { success, message } = await ReservationsApi
        .cancelReservation(cancelDialogData.reservationId);
      if (success === undefined && message === undefined) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Ok',
        });
        setCancelDialog(false);
        setApiCallConfig({
          ...pagination,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
        setCancelDialog(false);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      setCancelDialog(false);
    }
  };

  useEffect(() => {
    const calatogReservationStatus = localStorage.getItem('catalogReservationStatus');
    const getcatalogs = async () => {
      try {
        const { success, data } = await CatalogsApi
          .catalogReservationStatus();
        console.log(data);
        if (isTruthy(success)) {
          setCatalogReservationStatus(data);
          localStorage.setItem('catalogReservationStatus', JSON.stringify(data));
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (calatogReservationStatus === null || calatogReservationStatus === 'undefined') {
      getcatalogs();
    } else {
      setCatalogReservationStatus(JSON.parse(calatogReservationStatus));
      setLoading(false);
    }
  }, []);

  const showMenuOptions = (item) => {
    console.log(item);
    return true;
  };
  const openImageDialog = (image) => {
    setImageDialogData(image);
    setImageDialog(true);
  };
  const openHistoryDialog = (created_by, status_modified_by, modified_by, deleted_by) => {
    setHistoryDialogData({ created_by, status_modified_by, modified_by, deleted_by });
    setHistoryDialog(true);
  };

  const renderOffer = (offer) => {
    return <div><span class="blackbadge--offer">{offer.typeOffer}</span></div>
  }

  const renderAccordion = () => (
    <div className="reservations-accordion__container">
      <DialogImage
        isOpen={imageDialog}
        onNo={closeImageDialog}
        data={imageDialogData}
      />
      <DialogHistory
        isOpen={historyDialog}
        onNo={closeHistoryDialog}
        data={historyDialogData}
      />
      <DialogCancelReservation
        isOpen={cancelDialog}
        onYes={cancelReservation}
        onNo={closeCancelDialog}
        data={cancelDialogData}
      />
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={snackbarHandleClose}
      >
        <MuiAlert severity={snackbar.severity}>
          {snackbar.text}
        </MuiAlert>
      </Snackbar>
      {filterdReservations.map((item, index, arr) => (
        <>
          {((moment('05:00 pm', 'hh:mm A') > moment(arr[index].time, 'hh:mm A'))) ? index === 0 && renderDayRow() : index === 0 && renderNightRow()}
          {(orderBy === 'fecha' && order === 'asc' && index !== 0 && ((moment('05:00 pm', 'hh:mm A') <= moment(arr[index].time, 'hh:mm A')) && (moment('05:00 pm', 'hh:mm A') > moment(arr[index - 1].time, 'hh:mm A')))) ? renderNightRow() : ''}
          {(orderBy === 'fecha' && order === 'desc' && index !== 0 && ((moment('05:00 pm', 'hh:mm A') >= moment(arr[index].time, 'hh:mm A')) && (moment('05:00 pm', 'hh:mm A') < moment(arr[index - 1].time, 'hh:mm A')))) ? renderDayRow() : ''}
          <Accordion key={uuid()}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="reservations-accordion__header">
                <div className="dflex">
                  <button type="button" className="photocontainer" width="100px" onClick={() => openImageDialog((item.photo === null || item.photo === '') ? DefaulImage : item.photo)}>
                    {(item.photo === null || item.photo === '') ? (
                      <>
                        <img src={DefaulImage} alt="" width="100%" />
                      </>
                    ) : (
                      <>
                        <img src={item.photo} alt="" width="100%" />
                      </>
                    )}

                  </button>
                  <div className="holder">
                    <div className="reservations-accordion__categories dFlex flex-row font__roboto">
                      {item.categories.map((category) => prepareCategoryList(category))}
                    </div>
                    <Typography className="reservations-accordion__name ">
                      {item.name !== null && (item.clientStatus === 'Pendiente' || item.clientStatus === 'Invitado') && (
                        <>
                          <span>
                            {item.name}
                          </span>
                        </>
                      )}
                      {item.name !== null && (item.clientStatus === 'Aceptado' || item.clientStatus === 'Invitado') && (
                        <>
                          <span className="vip">
                            {item.name}
                          </span>
                        </>
                      )}
                      {item.name !== null && item.clientStatus !== 'Aceptado' && item.clientStatus !== 'Invitado' && (
                        <>
                          <span>
                            {item.name}
                          </span>
                        </>
                      )}
                      {item.name === null && (
                        'Sin datos'
                      )}
                    </Typography>

                    <div>
                      <div className="font__roboto">
                        {item.people}
                        {' '}
                        personas
                      </div>
                      <div className="font__roboto">
                        {item.time}
                        {' '}
                        ·
                        {' '}
                        {item.date}
                      </div>
                      <div className="font__roboto">
                        {item.type === 'pardepan' && renderpardepan()}
                        {item.type === 'waitinglist' && renderwaitinglist()}
                        {item.type === 'event' && renderevent(item)}
                        {item.birthday !== null && item.birthday !== '' && renderBirthday(item.birthday)}

                      </div>
                      <div className="font__roboto">
                        <b>Mesa:</b>
                        {' '}
                        {(item.tableNumber) ? item.tableNumber : 'Sin datos'}
                      </div>
                      <div className="font__roboto">
                        <b>Zona:</b>
                        {' '}
                        {(item.table) ? item.table : ''}
                      </div>
                      <div className="font__roboto">
                        <b>Visitas:</b>
                        {' '}
                        {(item.visits) ? item.visits : 0}
                      </div>
                      {item.offer !== '' ? renderOffer(item.offer) : null}
                      <div>
                        <div className="font__roboto">
                          <b>¿Quien hizo que?</b>
                          <IconButton
                            className="reservations-accordion__history"
                            onClick={() => openHistoryDialog(item.created_by, item.status_modified_by, item.modified_by, item.deleted_by)}
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            size="small"
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reservations-d__flex mt-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  {item.email !== 'client@kokonutstudio.com' && (
                    <>
                      <div className="font__roboto">
                        <CustomColorIconButton size="small" onClick={() => openemailDialog(item.name, item.email, item.phone)}>
                          <Phone className="contacticon" />
                          <span className="contacttext">
                            VER CONTACTO
                          </span>
                        </CustomColorIconButton>
                      </div>
                    </>
                  )}
                </div>
                <div className="reservations-d__flex mt-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                {(item.status !== 'CANCELADO POR EL CLIENTE')
                  ? (
                    <MenuReservationStatus
                      id={item.id}
                      status={(item.status === 'EN ESPERA') ? 'ANOTADA POR EL RESTAURANTE' : (item.status === 'LISTA DE ESPERA') ? 'PENDIENTE POR CONTESTAR' : item.status}
                      type={item.type}
                      catalog={catalogReservationStatus}
                      reservationDate={item.dateRaw}
                      setNewStatus={() => {
                        setApiCallConfig({
                          ...pagination,
                        });
                      }}
                  />
                  ) : (
                    <>
                      <span className="canceled">
                        {item.status}
                      </span>
                    </>
                  )}
                </div>
            </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="reservations-accordion__expansion">
                <Typography className="reservations-accordion__expansion-comments">
                  {item.comments.length > 0 ? item.comments : 'Sin comentarios'}
                </Typography>
                {showMenuOptions(item) && (
                  <MenuTableOptions className="reservations-accordion__expansion-button" id={item.id} options={menuOptions(item.id, item.name, item.table, item.dateRaw, item.linkid)} />
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
      <DialogEmail
        isOpen={emailDialog}
        onNo={closeEmailDialog}
        data={emailDialogData}
      />
      <DialogEditClient
        isOpen={EditClientDialog}
        onNo={closeEditClientDialog}
        data={editClientDialogData}
      />
    </div>
  );

  return (
    <>
      {!loading && renderAccordion()}
    </>
  );
};

export default ReservationsAccordion;
