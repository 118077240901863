import React, { useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificacionesEmailContext from '../../layouts/NotificacionesEmail/NotificacionesEmailContext/NotificacionesEmailContext';

const NotificacionesEmailAccordion = () => {
  const { filteredNotifications } = useContext(NotificacionesEmailContext);

  const handleEdit = (notification) => {
    console.log('Editar', notification);
  };

  const handleDelete = (notification) => {
    console.log('Eliminar', notification);
  };

  return (
    <div className="w100">
      {filteredNotifications.map((notification) => (
        <Accordion key={notification.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{notification.email}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="w100">
              <Typography>
                <strong>Rol:</strong>
                {' '}
                {notification.role}
              </Typography>
              <Typography>
                <strong>Última Inicialización:</strong>
                {' '}
                {notification.lastInitialization}
              </Typography>
              <Box className="dFlex justify-end mt-2">
                <IconButton
                  onClick={() => handleEdit(notification)}
                  edge="end"
                  color="primary"
                  aria-label="edit"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(notification)}
                  edge="end"
                  color="secondary"
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default NotificacionesEmailAccordion;
