/* eslint-disable */
import React, { useState } from 'react';
import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  MenuItem,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TheMenu from '../TheMenu';
import axios from 'axios';
import TokenManager from '../../api/utils/TokenManager';

const roleOptions = [
  { value: 'admin', label: 'Administrador' },
  { value: 'manager', label: 'Gerente' },
  { value: 'user', label: 'Usuario' },
];

const CrearNotificacionEmail = () => {
  const [formData, setFormData] = useState({
    email: '',
    role: '',
    name: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = new TokenManager('access').getToken();
      const response = await axios.post('https://api21.com.do/api/v1/createdEmailNoti', 
        {
          email: formData.email,
          role: formData.role,
          name: formData.name || undefined
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate('/notificaciones-email');
        }, 2000);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Error al crear la notificación');
    }
  };

  return (
    <>
      <TheMenu />
      <Container maxWidth="sm" style={{ marginTop: '24px' }}>
        <Paper style={{ padding: '24px' }}>
          <Box className="dFlex flex-column">
            <Typography variant="h5" component="h1" gutterBottom>
              Crear Notificación Email
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                margin="normal"
              />
              <TextField
                fullWidth
                select
                label="Rol"
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
                margin="normal"
              >
                {roleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Nombre (Opcional)"
                name="name"
                value={formData.name}
                onChange={handleChange}
                margin="normal"
              />
              <Box mt={3} className="dFlex flex-row justify-end">
                <Button
                  type="button"
                  onClick={() => navigate('/notificaciones-email')}
                  style={{ marginRight: '16px' }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Crear
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>

        <Snackbar 
          open={!!error} 
          autoHideDuration={6000} 
          onClose={() => setError('')}
        >
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>

        <Snackbar 
          open={success} 
          autoHideDuration={6000} 
          onClose={() => setSuccess(false)}
        >
          <Alert onClose={() => setSuccess(false)} severity="success">
            Notificación creada exitosamente
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default CrearNotificacionEmail;
