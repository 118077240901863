/* eslint-disable */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TokenManager from '../../api/utils/TokenManager';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from '@material-ui/core';
import MenuTableOptions from '../MenuTableOptions';
import moment from 'moment';
import NotificacionesEmailContext from '../../layouts/NotificacionesEmail/NotificacionesEmailContext/NotificacionesEmailContext';
import DialogDeleteNotificacion from '../DialogDeleteNotificacion/DialogDeleteNotificacion';
import SnackbarAlert from '../../components/SnackbarAlert';
import axios from 'axios';

import './OffersTable.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [{
  key: '1',
  id: 'id',
  label: 'ID',
  disablePadding: false,
  sortable: true,
}, {
  key: '2',
  id: 'email',
  label: 'EMAIL',
  disablePadding: false,
  sortable: true,
}, {
  key: '3',
  id: 'role',
  label: 'ROL',
  disablePadding: false,
  sortable: true,
}, {
  key: '4',
  id: 'created_at',
  label: 'CREACIÓN',
  disablePadding: false,
  sortable: true,
}];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const { filteredNotifications, setReload } = useContext(NotificacionesEmailContext);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState({ email: '', id: null });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const openDeleteDialog = (id, email) => {
    setDeleteDialogData({ email, id });
    setDeleteDialog(true);
  };

  const menuOptions = (id, email) => [
    { key: 1, string: 'ELIMINAR', action: () => { openDeleteDialog(id, email); } },
  ];

  const deleteNotificacion = async () => {
    try {
      const token = new TokenManager('access').getToken();
      
      const response = await fetch(`https://api21.com.do/api/v1/deletedEmailNoti/${deleteDialogData.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.status === 200) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Notificación eliminada exitosamente',
        });
        closeDeleteDialog();
        setTimeout(() => {
          setReload(prev => !prev);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'Error al eliminar la notificación',
        });
        closeDeleteDialog();
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: 'Error al eliminar la notificación',
      });
      closeDeleteDialog();
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(filteredNotifications, getComparator(order, orderBy))
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                    <TableCell align="left">
                      {row.created_at ? moment(row.created_at).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell align="right">
                      <MenuTableOptions options={menuOptions(row.id, row.email)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DialogDeleteNotificacion
        isOpen={deleteDialog}
        onYes={deleteNotificacion}
        onNo={closeDeleteDialog}
        data={deleteDialogData}
      />
      <SnackbarAlert
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
        text={snackbar.text}
      />
    </div>
  );
};

export default EnhancedTable;
