import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const DialogDeleteNotificacion = ({
  isOpen, onYes, onNo, data,
}) => (
  <Dialog
    open={isOpen}
    onClose={onNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Eliminar Notificación</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        ¿Estás seguro que deseas eliminar la notificación de correo para
        {' '}
        {data.email}
        ?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onNo} color="primary">
        No
      </Button>
      <Button onClick={onYes} color="primary" autoFocus>
        Sí
      </Button>
    </DialogActions>
  </Dialog>
);

DialogDeleteNotificacion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default DialogDeleteNotificacion;
