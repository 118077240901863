/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  NativeSelect,
  FormControl,

} from '@material-ui/core/';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DatePicker from 'react-multi-date-picker';
import gregorianes from 'react-date-object/locales/gregorian_es';
import * as OffersApi from '../../api/restaurants/offers';
import TableErrors from '../../components/TableErrors';
import SnackbarAlert from '../../components/SnackbarAlert';
import TheMenu from '../../components/TheMenu';
import './StaffCreate.scss';
import emojiRegex from '../../utils/emojiRegex';

const StaffCreate = () => {
  const [validations, setValidations] = useState({
    date: true,
    time: true,
    name: true,
    email: true,
    phone: true,
    startDate: true,
    startTime: true,
    endTime: true,
    endDate: true,
  });
  const [state, setState] = useState({
    loading: true,
    error: false,
    success: false,
    message: '',
  });
  const [fields, setFields] = useState({
    title: '',
    description: '',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    type: 'oferta',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const [repeat, setRepeat] = useState(true);
  const [values, setValues] = useState([]);
  const [repeatDays, setRepeatDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    allDays: false,
  });
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [isCalendarStartDateOpen, setIsCalendarStartDateOpen] = useState(false);
  const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = useState(false);

  useEffect(() => {
    setState({
      loading: false,
      error: false,
      success: false,
      message: '',
    });
  }, []);

  useEffect(() => {

  }, [fields]);
  const handleAllDaysChange = () => {
    setRepeatDays({
      monday: !repeatDays.allDays,
      tuesday: !repeatDays.allDays,
      wednesday: !repeatDays.allDays,
      thursday: !repeatDays.allDays,
      friday: !repeatDays.allDays,
      saturday: !repeatDays.allDays,
      sunday: !repeatDays.allDays,
      allDays: !repeatDays.allDays,
    });
  };

  const handleValidations = (field, value) => {
    const mapValidations = {
      title: (title) => title.length > 0,
      description: (description) => description.length > 0,
      startDate: (sdate) => sdate.length > 0,
      endDate: (ddate) => ddate.length > 0,
      type: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const [hasOffers, setHasOffers] = useState(true);

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };
  const handleDateStartDateChange = (date) => {
    setStartDate(date);
    const target = format(new Date(date), 'yyyy-MM-dd');
    updateFields('startDate', target);
  };
  const changeCheckbox = () => {
    setHasOffers(!hasOffers);
  };
  const handleDateEndDateChange = (date) => {
    setEndDate(date);
    const target = format(new Date(date), 'yyyy-MM-dd');
    updateFields('endDate', target);
  };
  const saveChanges = async () => {
    if (!validations.title) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: 'El título no puede estar vacío',
      });
      return;
    }
    if (!validations.description) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: 'La descripción no puede estar vacía',
      });
      return;
    }
    let numberhasoffers = 0;
    if (hasOffers) {
      numberhasoffers = 1;
    }
    const body = {};
    body.hasOffers = numberhasoffers;
    body.title = fields.title;
    body.description = fields.description;
    body.startDate = fields.startDate;
    body.endDate = fields.endDate;
    body.type = fields.type;

    console.log('data');
    console.log(body);
    const parseddates = [];
    if (values) {
      // use foreach to iterate over the values array
      values.forEach((date) => {
        // format the date to the correct format
        const parseddate = format(new Date(date), 'yyyy-MM-dd');
        // push the parsed date to the parseddates array
        parseddates.push(parseddate);
      });
      console.log('parseddates');
      console.log(parseddates);
    }
    const jsonVariable = JSON.stringify(parseddates);

    body.dates = jsonVariable;
    body.repeat = repeat;
    body.monday = repeatDays.monday;
    body.tuesday = repeatDays.tuesday;
    body.wednesday = repeatDays.wednesday;
    body.thursday = repeatDays.thursday;
    body.friday = repeatDays.friday;
    body.saturday = repeatDays.saturday;
    body.sunday = repeatDays.sunday;

    try {
      const { success, message } = await OffersApi.createOffers(body);
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          navigate('/ofertas');
        }, 1500);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  const discardChanges = () => {
    navigate('/ofertas');
  };
  const renderView = () => (
    <>
      <div className="w-100 mx-2">
        <div className="RestaurantOffer-form-container mr-2">
          <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
            <span>
              <b>Oferta Disponible</b>
              <br />
            </span>
          </div>
          <div className="RestaurantOffer__form">
            <div className="RestaurantOffer__form__checkbox">
              <div style={{ color: '#2b57d0' }}>
                <FormControlLabel
                  key={uuid()}
                  label="Activar funcionalidad"
                  className="custom-label"
                  control={(
                    <Checkbox
                      checked={hasOffers}
                      name="hasOffers"
                      disableRipple
                      color="primary"
                      onChange={() => { changeCheckbox(); }}
                    />
                  )}
                />
              </div>
              <div key={uuid()} className="restaurantCapacity-area-option">
                <div className="textoContador" style={{ color: 'gray', fontFamily: 'roboto', padding: '10px' }}>
                  Esta opción ofrece la posibilidad de presentar a los usuarios de Reserva Ya la disponibilidad de ofertas en su restaurante, permitiendo que aparezcan en los filtros de búsqueda
                </div>
              </div>
            </div>
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              id="title"
              label="Título"
              onChange={(e) => updateFields('title', e.target.value)}
              value={fields.title}
            />
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              id="description"
              label="Descripción"
              onChange={(e) => updateFields('description', e.target.value)}
              value={fields.description}
            />
          </div>
          <div className="w100 mb-1">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Tipo de experiencia
                </InputLabel>
                <NativeSelect
                  defaultValue={fields.type}
                  inputProps={{
                    name: 'type',
                    id: 'uncontrolled-native',
                  }}
                  onChange={(e) => {
                    updateFields('type', e.target.value);
                  }}
                >
                  <option value="Actividades">Actividades</option>
                  <option value="Happy hour">Happy hour</option>
                  <option value="Musica en Vivo">Musica en Vivo</option>
                  <option value="Oferta">Oferta</option>
                  <option value="Ofertas Especiales">Ofertas Especiales</option>
                  <option value="Productos de temporada">Productos de temporada</option>
                  <option value="Restaurant week">Restaurant week</option>
                  <option value="Transmisión de eventos deportivos">Transmisión de eventos deportivos</option>

                </NativeSelect>
              </FormControl>
            </Box>
          </div>
          <div>
            <div>
              <p>
                <b className="font__commutter custom-color" style={{ fontFamily: 'CommutersSans, sans-serif !important', fontWeight: 'bold', color: '#2b57d0' }}>
                  Si selecciona las fechas de la opción 2 por favor, asegúrese que no hay fechas seleccionadas en la opción 1.
                </b>
              </p>
            </div>
            <div>
              <p>
                <b className="font__commutter" style={{ fontFamily: 'CommutersSans, sans-serif !important', fontWeight: 'bold', color: '#2b57d0' }}>1 </b>
                Selecciona las fechas en la que se promocionarà la oferta
              </p>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <div className="mb-2">
                <DatePicker
                  multiple
                  value={values}
                  onChange={setValues}
                  locale={gregorianes}
                />
              </div>
            </MuiPickersUtilsProvider>

          </div>
          <div className="w100 mb-1">
            <div className="RestaurantOffer__form__checkbox " style={{ display: 'none' }}>
              <div>
                <FormControlLabel
                  key={uuid()}
                  label="Repetir ofertaa"
                  control={(
                    <Checkbox
                      checked={repeat}
                      name="repeat"
                      disableRipple
                      color="primary"
                      onChange={() => { setRepeat(!repeat); }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            <p>
              <b className="font__commutter" style={{ fontFamily: 'CommutersSans, sans-serif !important', fontWeight: 'bold', color: '#2b57d0' }}>2 </b>
              Selecciona el rango de fechas donde se repetira la oferta los dias que escojas
            </p>
          </div>
          <div className="row">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <div className="w100">
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarStartDateOpen(true); }}
                      onClose={() => setIsCalendarStartDateOpen(false)}
                      open={isCalendarStartDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de inicio de la oferta"
                      invalidLabel="Fecha invalida"
                      invalidDateMessage="No es un formato válido"
                      value={startDate}
                      onChange={handleDateStartDateChange}
                      disableToolbar
                      autoOk
                    />

                  </Grid>
                  <Grid item xs={12} md={2} />
                  <Grid item xs={12} md={5}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarEndDateOpen(true); }}
                      onClose={() => setIsCalendarEndDateOpen(false)}
                      open={isCalendarEndDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de finalización de la oferta"
                      value={endDate}
                      onChange={handleDateEndDateChange}
                      disableToolbar
                      autoOk
                    />
                  </Grid>
                </Grid>
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="weekdays-holder">
            <div className="mt-2 " style={{ fontFamily: 'CommutersSans, sans-serif !important', fontWeight: 'bold', color: '#2b57d0' }}>
              Si ha seleccionado el rango de fechas de la opción 2, acuérdese de seleccionar los días de la semana que desea que aparezca la oferta durante ese periodo de tiempo.
              <br />
              Aconsejamos seleccionar todos los días.
            </div>
            <div className="weekdays">
              <FormControlLabel
                key={uuid()}
                label="Todos los días"
                control={(
                  <Checkbox
                    checked={repeatDays.allDays}
                    name="allDays"
                    disableRipple
                    color="primary"
                    onChange={handleAllDaysChange}
                  />
                  )}
              />
            </div>
            <div className="weekdays">
              <FormControlLabel
                key={uuid()}
                label="Lunes"
                control={(
                  <Checkbox
                    checked={repeatDays.monday}
                    name="monday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, monday: !repeatDays.monday });
                    }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Martes"
                control={(
                  <Checkbox
                    checked={repeatDays.tuesday}
                    name="tuesday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, tuesday: !repeatDays.tuesday });
                    }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Miércoles"
                control={(
                  <Checkbox
                    checked={repeatDays.wednesday}
                    name="wednesday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, wednesday: !repeatDays.wednesday });
                    }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Jueves"
                control={(
                  <Checkbox
                    checked={repeatDays.thursday}
                    name="thursday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, thursday: !repeatDays.thursday });
                    }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Viernes"
                control={(
                  <Checkbox
                    checked={repeatDays.friday}
                    name="friday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, friday: !repeatDays.friday });
                    }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Sábado"
                control={(
                  <Checkbox
                    checked={repeatDays.saturday}
                    name="saturday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, saturday: !repeatDays.saturday });
                    }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Domingo"
                control={(
                  <Checkbox
                    checked={repeatDays.sunday}
                    name="sunday"
                    disableRipple
                    color="primary"
                    onChange={() => {
                      setRepeatDays({ ...repeatDays, sunday: !repeatDays.sunday });
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="RestaurantOffer__cta-container">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => { discardChanges(); }}
            >
              CANCELAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => { saveChanges(); }}

            >
              GUARDAR
            </Button>
          </div>
        </div>
      </div>
    </>
  );
  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderError = () => {
    const errorData = {
      message: state.message,
      action: () => { navigate('/staff'); },
      buttonText: 'Regresar',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={() => navigate('/staff')} />
    );
  };
  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="staff-edit layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <Box
          component="div"
          className="staff-edit__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <div className="dFlex flex-row align-center justify-start">
            <Typography variant="h6" className="staff-edit__title font__commutter">
              NUEVA EXPERIENCIA
            </Typography>
          </div>
        </Box>
        <Box
          component="div"
          className="w100 dFlex flex-column align-center justify-between"
        >
          {state.loading && renderLoading()}
          {!state.loading && state.error && renderError()}
          {!state.loading
            && !state.error
            && renderView()}

        </Box>
      </Container>
    </>
  );
};

export default StaffCreate;
