/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import isTruthy from '../../utils/isTruthy';
import client from '../../api/utils/client';
import TokenManager from '../../api/utils/TokenManager';
import NotificacionesEmailContext from './NotificacionesEmailContext/NotificacionesEmailContext';
import NotificacionesEmailTable from '../../components/NotificacionesEmailTable';
import NotificacionesEmailAccordion from '../../components/NotificacionesEmailAccordion';
import TableErrors from '../../components/TableErrors';
import SnackbarAlert from '../../components/SnackbarAlert';
import TheMenu from '../../components/TheMenu';

import './NotificacionesEmail.scss';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const NotificacionesEmail = () => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
    notifications: [],
  });

  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [reload, setReload] = useState(false);
  const [nameForFilter, setNameForFilter] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const fetchNotifications = async () => {
    try {
      setState({ ...state, loading: true });
      const token = new TokenManager('access').getToken();
      
      const response = await fetch('https://api21.com.do/api/v1/getEmails', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();

      if (data.success === 1) {
        setNotifications(data.data);
        setFilteredNotifications(data.data);
        setState({
          loading: false,
          error: null,
          success: true,
          notifications: data.data,
        });
      } else {
        setState({
          loading: false,
          error: 'Error al cargar las notificaciones',
          success: false,
          notifications: [],
        });
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setState({
        loading: false,
        error: 'Error al cargar las notificaciones',
        success: false,
        notifications: [],
      });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [reload]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setNameForFilter(searchTerm);

    if (searchTerm === '') {
      setFilteredNotifications(notifications);
    } else {
      const filtered = notifications.filter(
        (notification) =>
          notification.email.toLowerCase().includes(searchTerm) ||
          notification.role.toLowerCase().includes(searchTerm)
      );
      setFilteredNotifications(filtered);
    }
  };

  const renderLoading = () => (
    <Backdrop className={classes.backdrop} open={state.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const renderError = () => (
    <TableErrors
      error={state.error}
      action={() => {
        setReload(!reload);
      }}
    />
  );

  return (
    <>
      <TheMenu />
      <Container maxWidth="lg" style={{ marginTop: '24px' }}>
        <Box
          component="div"
          className="notifications-table__box w100 dFlex flex-column"
        >
          <Box
            component="div"
            className="dFlex flex-row align-center justify-between mb-4"
          >
            <Typography variant="h5" component="h1">
              Notificaciones Email
            </Typography>
            <Box className="dFlex flex-row align-center">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate('/crear-notificacion-email')}
                style={{ marginRight: '16px' }}
              >
                Crear Notificación
              </Button>
              <IconButton
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <Box
                component="div"
                className="notifications-table__search-container dFlex flex-row align-center"
              >
                <TextField
                  className="notifications-table__search-input"
                  placeholder="Buscar..."
                  value={nameForFilter}
                  onChange={handleSearch}
                />
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setNameForFilter('');
                    setFilteredNotifications(notifications);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>

          {state.loading && renderLoading()}
          {state.error && renderError()}
          {!state.loading && !state.error && (
            <NotificacionesEmailContext.Provider value={{
              filteredNotifications,
              setReload,
            }}>
              <Hidden xsDown>
                <NotificacionesEmailTable />
              </Hidden>
              <Hidden smUp>
                <NotificacionesEmailAccordion />
              </Hidden>
            </NotificacionesEmailContext.Provider>
          )}
        </Box>
        <SnackbarAlert
          open={snackbar.open}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          text={snackbar.text}
        />
      </Container>
    </>
  );
};

export default NotificacionesEmail;
