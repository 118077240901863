import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import SnackbarAlert from '../SnackbarAlert';

import * as RestaurantsApi from '../../api/restaurants/restaurants';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import isTruthy from '../../utils/isTruthy';
import websiteRegex from '../../utils/websiteRegex';
import emojiRegex from '../../utils/emojiRegex';

import './RestaurantMenu.scss';

const RestaurantMenu = ({ setReload }) => {
  // const [value, setValue] = useState(0);
  const { menuData, linkData, specialmenuData } = useContext(RestaurantContext);
  const [fields, setFields] = useState({ menu: '', link: '', specialmenu: '' });

  const [validations, setValidations] = useState({
    menu: true,
    link: true,
    specialmenu: true,

  });

  const [hasEditedField, setHasEditedField] = useState(false);

  const [titulosMenus, setTitulosMenus] = useState([]);
  const [menus, setMenus] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const obtenerMenus = async () => {
    try {
      const data = await RestaurantsApi.getMenus();
      if ('data' in data) {
        setMenus(data.data);
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Menus Cargados',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'No se pudieron obtener los menus',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
    }
  };

  const obtenerTiposMenus = async () => {
    try {
      const data = await RestaurantsApi.getTypetMenus();
      if ('data' in data) {
        obtenerMenus();
        setTitulosMenus(data.data);
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Menus Cargados',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'No se pudieron obtener los menus',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
    }
  };

  useEffect(() => {
    setFields({ menu: menuData !== null && menuData.length > 0 ? menuData : '', link: linkData !== null && linkData.length > 0 ? linkData : '', specialmenu: specialmenuData });
  }, [menuData, linkData, specialmenuData]);

  useEffect(() => {
    obtenerTiposMenus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidations = (field, value) => {
    const mapValidations = {
      menu: (menu) => websiteRegex.test(menu),
      link: () => true,
      specialmenu: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    setHasEditedField(true);
    handleValidations(field, cleanString);
  };

  const darUrl = (id) => {
    let result = '';
    menus.forEach((m) => {
      if (m.menuTypeId === id) {
        result = m.url;
      }
    });
    return result;
  };

  // const updateMenus = (field, value) => {
  //   const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
  //   const newFields = {
  //     ...menuUrls,
  //   };
  //   newFields[field] = cleanString;
  //   setMenuUrls(newFields);
  // };
  //
  const guardarMenu = async (id) => {
    try {
      const url = document.querySelector(`#menu-${id}`).value;
      const { message } = await RestaurantsApi.updateMenus({
        menu_type_id: id,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        menuurl: url,
      });
      if (message === 'Menu updated successfully.' || message === 'Menu deleted successfully.') {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Menu actualizados correctamente',
        });
        obtenerMenus();
        // setTimeout(() => {
        //   setReload(true);
        // }, 1500);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'Los menús no pudieron ser actualizados correctamente',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations.menu) {
      try {
        const { success, message } = await RestaurantsApi.editMenu({
          menu_url: fields.menu,
          link_url: fields.link,
          specialmenu_url: fields.specialmenu,

        });
        if (isTruthy(success)) {
          setSnackbar({
            open: true,
            severity: 'success',
            text: message,
          });
          // setTimeout(() => {
          //   setReload(true);
          // }, 1500);
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
        }
      } catch (error) {
        console.log(error);
        setSnackbar({
          open: true,
          severity: 'error',
          text: error.toJSON().message,
        });
        setTimeout(() => {
          setReload(true);
        }, 1500);
      }
    }
  };

  const renderForm = () => (
    <form onSubmit={validations.menu && hasEditedField && handleSubmit} className="w100 dFlex flex-column align-center justify-center" autoComplete="off">
      <div className="w90 card-menu">
        <div>
          <h3>Link del menú</h3>
          <div className="restaurantMenu-form__field-container">
            <TextField
              className="border-bottom-lite w100"
              error={!validations.menu}
              id="menu"
              onChange={(e) => updateFields('menu', e.target.value)}
              value={fields.menu}
            />
          </div>
        </div>
        <div className="dflex justify-end">
          <div className="restaurantMenu-form__buttonGroup mt-1">
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              GUARDAR
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="w90 card-menu">
        <div>
          <h3 className="mt-2">Link de los salonesdeeventos</h3>
          <div className="restaurantMenu-form__field-container">
            <TextField
              className="border-bottom-lite w100"
              error={!validations.link}
              id="link"
              onChange={(e) => updateFields('link', e.target.value)}
              value={fields.link}
            />
          </div>
        </div>
        <div className="dflex justify-end">
          <div className="restaurantMenu-form__buttonGroup mt-1">
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              GUARDAR
            </Button>
          </div>
        </div>
      </div>
      <div className="w90 card-menu">
        <div>
          <h3 className="mt-2">Link del menú especial (Navidad, Restaurant Week, etc.)</h3>
          <div className="restaurantMenu-form__field-container">
            <TextField
              className="border-bottom-lite w100"
              error={!validations.specialmenu}
              id="specialmenu"
              onChange={(e) => updateFields('specialmenu', e.target.value)}
              value={fields.specialmenu}
            />
          </div>
        </div>
        <div className="dflex justify-end">
          <div className="restaurantMenu-form__buttonGroup mt-1">
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              GUARDAR
            </Button>
          </div>
        </div>
      </div> */}
      {/* Menus nuevos */}
      {titulosMenus.map((menu) => (
        <div className="w90 card-menu" key={uuid()}>
          <div>
            <h3>
              Link
              {' '}
              {menu.title}
            </h3>
            <div className="restaurantMenu-form__field-container">
              <TextField
                key={uuid()}
                className="border-bottom-lite w100"
                id={`menu-${menu.id}`}
                defaultValue={darUrl(menu.id)}
              />
            </div>
          </div>
          <div className="dflex justify-end">
            <div className="restaurantMenu-form__buttonGroup mt-1">
              <Button
                variant="contained"
                color="primary"
                onClick={() => { guardarMenu(menu.id); }}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </div>
      ))}
      {/* Fin menus nuevos */}
    </form>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={8}>
            {renderForm()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantMenu.propTypes = {
  setReload: PropTypes.func,
};

RestaurantMenu.defaultProps = {
  setReload: () => { },
};

export default RestaurantMenu;
